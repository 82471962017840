<template>
  <v-app>
    <v-app-bar app color="white" height="70">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="d-flex d-sm-none"
      ></v-app-bar-nav-icon>

      <v-toolbar-title> Michele's Esthetics </v-toolbar-title>
      <v-spacer />
      <v-btn text class="d-none d-sm-flex" @click="$vuetify.goTo('#about')"
        >About</v-btn
      >
      <v-btn text class="d-none d-sm-flex" @click="$vuetify.goTo('#services')"
        >Services</v-btn
      >
      <v-btn text class="d-none d-sm-flex" @click="$vuetify.goTo('#contact')"
        >Contact</v-btn
      >
      <v-btn
        class="d-none d-sm-flex"
        color="indigo darken-3 white--text"
        href="https://michelesesthetics.setmore.com"
        rounded
      >Book Online</v-btn>
    </v-app-bar>
    <v-navigation-drawer v-if="$vuetify.breakpoint.mobile" v-model="drawer" app>
      <v-list nav dense>
        <v-list-item>
          <v-btn
            color="indigo darken-3 white--text"
            href="https://michelesesthetics.setmore.com"
            rounded
            >
            Book Online
          </v-btn>
        </v-list-item>
        <v-list-item
          @click="
            $vuetify.goTo('#about');
            drawer = false;
          "
        >
          <v-list-item-title>About</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="
            $vuetify.goTo('#services');
            drawer = false;
          "
        >
          <v-list-item-title>Services</v-list-item-title>
        </v-list-item>

        <v-list-item
          @click="
            $vuetify.goTo('#contact');
            drawer = false;
          "
        >
          <v-list-item-title>Contact</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-content>
      <section
        id="hero"
        :style="`background: url(${require('@/assets/egypt.jpg')})`"
      >
        <v-row align="center" class="white--text mx-auto py-5" justify="center">
          <v-col
            style="background: rgba(0, 0, 0, 0.7)"
            class="white--text text-center"
            cols="6"
            tag="h1"
          >
            <span
              :class="[
                $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2',
              ]"
              class="font-weight-thin"
            >
              Serving Ottawa Since
            </span>

            <br />

            <span
              :class="[
                $vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4',
              ]"
              class="font-weight-black"
            >
              1997
            </span>
          </v-col>
        </v-row>
      </section>

      <div class="py-12"></div>

      <section id="about">
        <v-container class="text-center pb-0">
          <v-avatar class="elevation-12 mb-6" size="128">
            <v-img src="@/assets/michele.jpg"></v-img>
          </v-avatar>

          <v-responsive
            class="mx-auto title font-weight-light mb-8"
            max-width="720"
          >
            Michele has been trained as an Alexandria Practitioner since 1997.
            Since then, I have taken several updated courses from Alexandria
            Professional Body Sugaring TM Educators learning better techniques
            and more about their new products. I am a Graduate of Gina's School
            of Esthetics and Electrolysis where I was trained and educated in
            manicures and pedicures. I graduated from Belmonda Beauty Academy in
            Montreal learning the art of giving a professional facial. I am able
            to give the applicable facial depending on your needs and skin type.
          </v-responsive>
              <v-btn
            color="orange darken-3 white--text"
            href="https://michelesesthetics.setmore.com"
            rounded
          >Book an Appointment</v-btn>
        </v-container>
      </section>

      <div class="py-12"></div>

      <section id="services">
        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">SERVICES</h2>

          <v-responsive class="mx-auto mb-6" width="56">
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <h2 class="text-left">Sugaring - Women</h2>
                </v-card-title>
                <p class="mx-4 text-left">
                  <strong>Alexandria Professional Body Sugaring</strong> is a
                  type of hair removal with a unique blend of natural
                  ingredients with no chemical additives. This type of hair
                  removal uses products from the ancient Middle Egyptians. Body
                  sugaring is gentler, safer and can be used on all types of
                  skin and hair textures. The Alexandria Body Sugaring products
                  do not contain any resins, or preservatives. The product is
                  made of sugar, lemon and water. Alexandria Professional Body
                  Sugaring TM was founded in 1989. The company is based in North
                  America located in Ontario/Canada.
                </p>
                <v-simple-table class="text-left">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Service</th>
                        <th>Prices</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in womenSugaringServices" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>{{ item.price }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <h2 class="text-left">Sugaring - Men</h2>
                </v-card-title>
                <v-simple-table class="text-left">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Service</th>
                        <th>Prices</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in menSugaringServices" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>{{ item.price }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <h2>Unisex Manicure</h2>
                </v-card-title>
                <p class="mx-4 text-left">All manicures include: Soak, Cuticles, Cut & File, Massage and Hot Towel</p>
                <v-simple-table class="text-left">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Service</th>
                        <th>Prices</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in manicureServices" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>{{ item.price }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <h2>Unisex Pedicure</h2>
                </v-card-title>
                <p class="mx-4 text-left">All pedicures include: Soak, Cuticles, Cut & File, Massage and Hot Towel</p>
                <v-simple-table class="text-left">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Service</th>
                        <th>Prices</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in pedicureServices" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>{{ item.price }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <div class="py-12"></div>

      <v-sheet id="contact">
        <v-container class="text-center pb-0">
          <h2
            class="display-2 font-weight-bold mb-3 text-uppercase text-center"
          >
            Contact
          </h2>
          <v-responsive class="mx-auto mb-6" width="56">
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>
          <v-row>
            <v-col cols="12">
              <h3>Hours</h3>
              <p>
                <strong>Mon</strong> 12:30 pm - 8:30 pm<br />
                <strong>Tue</strong> 8:30 am - 5:00 pm<br />
                <strong>Wed</strong> 12:30 pm - 8:30 pm<br />
                <strong>Thu</strong> 8:30 am - 5:00 pm<br />
                <strong>Fri</strong> 8:30 am - 11:30 am<br />
                <strong>Sat</strong> 9:00 am - 5:00 pm<br />
              </p>
            </v-col>
            <v-col cols="12">
              <h3>Phone</h3>
              <p><a href="tel:613-297-8568">613-297-8568</a></p>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="orange darken-3 white--text"
                href="https://michelesesthetics.setmore.com"
                rounded
              >Book an Appointment</v-btn>
            </v-col>
          </v-row>          
        </v-container>

        <div class="py-6"></div>
      </v-sheet>
    </v-content>

    <v-footer class="justify-center" color="#292929" height="100">
      <div
        class="title font-weight-light grey--text text--lighten-1 text-center"
      >
        &copy; {{ new Date().getFullYear() }} — Made with 💜 by Justin
      </div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    drawer: false,
    snackbar: false,
    loading: false,
    form: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    valid: true,
    nameRules: [(v) => !!v || "Name is required"],
    subjectRules: [(v) => !!v || "Subject is required"],
    messageRules: [(v) => !!v || "Message is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    womenSugaringServices: [
      {
        name: "Back - Full Back",
        price: "$45.00",
      },
      {
        name: "Back - Low Back",
        price: "$25.00",
      },
      {
        name: "Bikini",
        price: "$35.00",
      },
      {
        name: "Brazilian",
        price: "$40.00",
      },
      {
        name: "Brazilian with Mud",
        price: "$55.00",
      },
      {
        name: "Buttocks",
        price: "$20.00",
      },
      {
        name: "",
        price: "",
      },
      {
        name: "Face - Chin",
        price: "$15.00",
      },
      {
        name: "Face - Upper Lip",
        price: "$20.00",
      },
      {
        name: "Face - Eyebrows",
        price: "$25.00",
      },
      {
        name: "Face - Chin and Upper Lip",
        price: "$30.00",
      },
      {
        name: "Face - Chin and Upper Lip, and Eyebrows",
        price: "$50.00",
      },
      {
        name: "Face - Full Face (not including eyebrows)",
        price: "$40.00",
      },
      {
        name: "Face - Full Face and Eyebrows",
        price: "$50.00",
      },
      {
        name: "",
        price: "",
      },
      {
        name: "Arms - Underarms",
        price: "$20.00",
      },
      {
        name: "Arms - Fingers and Elbows",
        price: "$30.00",
      },
      {
        name: "Arms - Full Arms (not including underarms)",
        price: "$40.00",
      },
      {
        name: "Arms - Full Arms and Underarms",
        price: "$55.00",
      },
      {
        name: "Legs - Upper Leg",
        price: "$55.00",
      },
      {
        name: "Legs - Lower Leg",
        price: "$40.00",
      },
      {
        name: "Legs - Full Leg",
        price: "$70.00",
      },
      {
        name: "Arms & Legs - Full Leg and Full Arms (not including underarms)",
        price: "$105.00",
      },
      {
        name: "Arms & Legs - Full Leg and Full Arms, and Underarms",
        price: "$120.00",
      }
    ],
    menSugaringServices: [
      {
        name: "Back - Full Back (excluding shoulders)",
        price: "$50.00",
      },
      {
        name: "Back - Full Back and Shoulders",
        price: "$65.00",
      },
      {
        name: "Chest",
        price: "$50.00",
      },
      {
        name: "Chest and Shoulders",
        price: "$65.00",
      },
      {
        name: "",
        price: "",
      },
      {
        name: "Ears",
        price: "$20.00",
      },
      {
        name: "Face - Eyebrows",
        price: "$20.00",
      },
      {
        name: "Face - Nose",
        price: "$15.00",
      },
      {
        name: "",
        price: "",
      },
      {
        name: "Manscape",
        price: "$65.00",
      },
      {
        name: "Manscape with Mud",
        price: "$80.00",
      }
    ],
    manicureServices: [
      {
        name: "Manicure with Nail Polish",
        price: "$50.00",
      },
      {
        name: "Manicure with Gel",
        price: "$65.00",
      }
    ],
    pedicureServices: [
      {
        name: "Basic Pedicure (No Polish or Gel)",
        price: "$50.00",
      },
      {
        name: "Pedicure with Nail Polish",
        price: "$65.00",
      },
      {
        name: "Pedicure with Gel",
        price: "$75.00",
      }
    ]
  }),
  methods: {
    setConsent() {
      this.form.subject = "Michele Esthetics Pre-Appointment Screening";
      this.form.message =
        "I confirm that the following statements are all true at the time this email is sent:" +
        "\n\nI currently have no fever or chills, no difficulty breathing, no chest pain, no new or worsening sore throat or cough, no loss of sensation of taste or smell, or no new body aches or pains" +
        "\n\nI have not had contact with someone who has tested positive for COVID-19 or has experienced symptoms in (1) or has returned to Canada from abroad within the past 14 days" +
        "\n\nI have not travelled outside of Canada within the past 14 days" +
        "\n\nI have been practicing physical distancing with people other than those living at my residence or as per the updated recommendations of the Ontario Ministry of Health";
      this.$vuetify.goTo("#email");
    },
    async sendEmail() {
      await this.$refs.form.validate();
      if (this.valid) {
        var data = new FormData();
        data.append("name", this.form.name);
        data.append("email", this.form.email);
        data.append("subject", this.form.subject);
        data.append("message", this.form.message);
        this.loading = true;
        await this.$axios.post('https://formspree.io/f/mleopykg', data);
        this.loading = false;
        this.snackbar = true;
        this.$refs.form.reset();
      }
    },
  },
};
</script>

<style scoped>
.v-toolbar__title {
  font-family: "Dancing Script", cursive !important;
  font-size: 32px;
}
</style>
